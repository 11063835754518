import React from "react"
import { graphql } from "gatsby"
import { Layout, Seo, Stories, Container, DownloadApp, RoundedBox } from "../../components"

import * as styles from './style.module.scss'

const WinnersTemplate = ({ data, pageContext }) => {
	const [category, setCategory] = React.useState("Futebol")

	const { winnersPageFields, seoMetaFields } = data.page
	const { sectionDownload } = data.downloadHomepageProps.homePageFields

	const metadata = seoMetaFields.wpFields.find(
    variant => variant.languageValue.slug === pageContext.lang
  )

	const page = winnersPageFields.winnersFields.find(
    variant => variant.languageValue.slug === pageContext.lang
  )

	const downloadHomeProps = sectionDownload.find(
    variant => variant.languageValue.slug === pageContext.lang
  )

	const downloadButtons = downloadHomeProps.buttons
	const downloadMockup = downloadHomeProps.mockup?.localFile.childImageSharp.gatsbyImageData.images.fallback
  const heroSection = page.topSection
  const mvpSection = page.mvpSection
  const winnersSection = page.winnersSection
  const downloadSection = page.downloadSection
  const storyPosts = data.featuredWinners.edges

  const handleSelect = value => {
    setCategory(value)
  }

  return (
    <Layout>
      <Seo title={metadata.seoTitle} description={metadata.seoDescription} />

			<section className={styles.winners}>
				<RoundedBox
					className={styles.roundedBox}
					transform="rotate(26deg)"
					width="1016px"
					height="1116px"
					position={{
						top: "calc(100vh - (50% + 60px))",
						left: "-680px"
					}}
				/>

				<div className={styles.winners__head}>
					<div
						className={styles.hero}
						style={{
							backgroundImage: `url(${heroSection.image.localFile.childImageSharp.gatsbyImageData.images.fallback.src})`
						}}
					>
						<Container className={styles.container}>
							<div className={styles.content}>
								<h1 className={styles.title}>{heroSection.title}</h1>
								<div className={styles.text} dangerouslySetInnerHTML={{__html: heroSection.copy}}></div>
							</div>
						</Container>
					</div>
				</div>
				<div className={styles.winners__body}>
					<Container>
						<div className={`${styles.video} flex align-center`}>
							<div className={styles.content}>
								<h2>{mvpSection.title}</h2>
								<h3>{mvpSection.name}</h3>
								<h4>{mvpSection.city}</h4>
								<div className={styles.text} dangerouslySetInnerHTML={{__html: mvpSection.copy}}></div>
							</div>
							
							{
								mvpSection.videoUrl &&
									<iframe className={styles.iframe} src={mvpSection.videoUrl} frameBorder="0" allowFullScreen></iframe>
							}
							
							{
								mvpSection.imageUrl &&
									<img className={styles.iframe} src={mvpSection.imageUrl.localFile.childImageSharp.gatsbyImageData.images.fallback.src}/>
							}
						</div>
					</Container>

					<div id="winnersStories" className={styles.stories}>
						<h1 className={styles.sectionTitle}>{winnersSection.title} WANNA</h1>
						{/* <Select
							className={styles.select}
							defaultValue="Futebol"
							options={[
								{ name: "Futebol", value: "Futebol" },
								{ name: "Futebol americano", value: "Futebol americano" },
								{ name: "Basquete", value: "Basquete" },
								{ name: "E-Sports", value: "E-Sports" }
							]}
							callback={handleSelect}
						/> */}

						<Stories data={{storyPosts}} language={pageContext.lang} slidesToShow={5} fullWidth={true} isWinnersPage={true} />
					</div>

					<Container>
						<div className={styles.download}>
							<DownloadApp data={downloadHomeProps} notHome={true}/>
						</div>
					</Container>
				</div>
			</section>
    </Layout>
  )
}

export const Query = graphql`
  query WinnersTemplate {
    page: wpPage(slug: { eq: "winners" }) {
      id
      seoMetaFields {
        wpFields {
          languageValue {
            slug
            name
          }
          seoTitle
          seoDescription
        }
      }
      winnersPageFields {
        winnersFields {
          languageValue {
            slug
            name
          }
          topSection {
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            title
            copy
          }
          mvpSection {
            title
            name
            city
            copy
            videoUrl
						imageUrl {
							localFile {
								childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
							}
						}
          }
          winnersSection {
            title
          }
          downloadSection {
            title
          }
        }
      }
    }
    featuredWinners: allWpWinner(
      filter: { winnersPostFields: { featured: { eq: true } } }
    ) {
      edges {
        node {
          winnersPostFields {
            winnersFields {
              languageValue {
                name
                slug
              }
              category {
                name
                slug
              }
              name
              location
              excerpt
              copy
							secondaryImage {
								localFile {
									childImageSharp {
										gatsbyImageData(
											width: 1074
											placeholder: NONE
											layout: CONSTRAINED
										)
									}
								}
							}
							thirdImage {
								localFile {
									childImageSharp {
										gatsbyImageData(
											width: 1074
											placeholder: NONE
											layout: CONSTRAINED
										)
									}
								}
							}
							secondaryContent
							thirdContent
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
		downloadHomepageProps: wpPage(slug: { eq: "home" }) {
			homePageFields {
				sectionDownload {
          languageValue {
            slug
            name
          }
          heading
          subheading
          buttons {
            appleLink
            appleImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
            androidLink
            androidImage {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    quality: 100
                    placeholder: NONE
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          mockup {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 100
                  placeholder: NONE
                  layout: FULL_WIDTH
                )
              }
            }
          }
        }
			}
		}
  }
`

export default WinnersTemplate
