// extracted by mini-css-extract-plugin
export var winners = "style-module--winners--LrQpw";
export var winners__head = "style-module--winners__head--1ivio";
export var hero = "style-module--hero--1r6Me";
export var container = "style-module--container--25h4f";
export var content = "style-module--content--282E2";
export var title = "style-module--title--2SjzW";
export var text = "style-module--text--aUbKc";
export var winners__body = "style-module--winners__body--3QPNE";
export var video = "style-module--video--2ioUg";
export var iframe = "style-module--iframe--1go0y";
export var stories = "style-module--stories--1CzCP";
export var sectionTitle = "style-module--section-title--1Mjo6";
export var select = "style-module--select--ULh3X";
export var download = "style-module--download--1YEDH";
export var roundedBox = "style-module--roundedBox--3zCIB";